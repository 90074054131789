import amc68k from "./lib/amc68k";

import "bootstrap";

import { createApp } from "vue";
import VueMatomo from "vue-matomo";
import App from "./App.vue";
import router from "./router";

amc68k.withVue(createApp(App))
  .greetTheWorld()
  .use(router)
  .useTracker(VueMatomo, router)
  .mount("#app");
