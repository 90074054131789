<template>
  <router-view />
</template>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";

$color-pearl: #f7f7f7;
$color-concrete: #e7e7e7;
$color-night: #1e2d40;
$color-rocket: #bf455b;

$font-text: Avenir, Helvetica, Arial, sans-serif;
$font-claim: Palatino, Georgia, Times, serif;

#app {
  font-family: $font-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 100vh;
  min-width: 100vw;

  background: $color-pearl;
  color: $color-night;

  overflow-x: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

@supports (-webkit-touch-callout: none) {
  #app {
    /* full height hack for safari / ios */
    min-height: -webkit-fill-available;
  }
  .row:before,
  .row:after {
    display: none;
  }
}
</style>
