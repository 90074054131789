<template>
  <header class="container-fluid">
    <nav class="navbar">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="#">amc68k</a>
        </li>
      </ul>
    </nav>
  </header>
  <main class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="display-2">Life is <br /> Art.</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="lead">
          My name is amc68k, I am an artist.<br />
          I create both analog and digital arts.
        </p>
      </div>
    </div>
  </main>
  <footer class="container-fluid">
    <div class="row no-gutters">
      <div class="col-auto">
        <img class="brand img-fluid" src="../assets/amc68k-silhouette.png" width="400" height="360" />
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style lang="scss">
$color-pearl: #f7f7f7;
$color-concrete: #e7e7e7;
$color-night: #1e2d40;
$color-rocket: #bf455b;

h1, h2, h3, h4, h5, h6, p {
  color: $color-night;
}

header {
  .navbar {
    font-size: 20px;
  }

  .nav-link {
    color: $color-rocket;
    font-weight: bold;
  }
}

main {
}

footer {
  .brand {
    position: relative;
    left: -200px;
  }
}
</style>
