import window from "window-or-global";
import releases from "../../public/releasenotes.json";

export default {
  withVue(app) {
    if (!app) return app;

    app.greetTheWorld = this.greetTheWorld;

    app.useTracker = (plugin, router) => {
      const isHostedOnDomain = this.isHostedOnDomain();
      if (!isHostedOnDomain) {
        console.warn("Running on unauthorised domain, disabling web tracking");
      }
      return app.useIf(this.isHostedOnDomain(), plugin, this.trackingOptions(router));
    };

    app.useIf = (condition, plugin, options) => {
      return condition ? app.use(plugin, options) : app;
    };

    return app;
  },
  greetTheWorld() {
    const latest = releases[0];

    console.info("Welcome to amc68k.com!");
    console.info(`Running «${latest.name}» (v${latest.version})`);

    return this;
  },
  isHostedOnDomain() {
    return !!this.hostname.match(/amc68k\.com/i);
  },
  isHostedOnNextSubdomain() {
    return !!this.hostname.match(/next\.amc68k\.com/i);
  },
  trackingOptions(router) {
    return {
      router: router,
      host: "https://ia.amc68k.com",
      siteId: 1 + this.isHostedOnNextSubdomain(),
      enableLinkTracking: true,
      disableCookies: true,
      domains: ["*.amc68k.com"]
    };
  },
  hostname: window.location.hostname || ""
};
